<template>
  <div class="header" :style="{ position: fixed ? 'fixed' : 'static', backgroundColor: isAction ? '#fff' : '#d35d53' }">
    <div class="container nav_box" v-if="isAction">
      <div>
        <img src="@assets/pc/logo.png" class="logo block" />
        <!-- <div class="btn">
          <img src="@assets/pc/icon_header_email.png" class="block">
          <p>168kaicn@gmail.com</p>
        </div> -->
        <div class="btn" @click="addFavoriteAction">
          <img src="@assets/pc/icon_header_favorites.png" class="block">
          <p>加入收藏</p>
        </div>
        <div class="btn" @click="setHomeAction">
          <img src="@assets/pc/icon_header_home.png" class="block">
          <p>设为主页</p>
        </div>
      </div>
    </div>
    <div v-else class="container head_box">
      <router-link to="/lottery/notifications" class="history_bt"
        :class="{ font_red: path.indexOf('/lottery/notifications') != -1 }">
        <!-- <img src="@assets/pc/head_notice.png"/> -->
        历史公告
      </router-link>
      <div class="notice_box">
        <vue-marquee-bar :content="notices" :speed="260" background="transparent" color="#282828">
          <template v-slot:content="scope">
            <div style="color: #fff;" v-html="scope.data"></div>
          </template>
        </vue-marquee-bar>
      </div>
      <div class="user_box">
        <span v-if="!is_audio" class="sound_box" @click="is_audio = !is_audio">
          <img src="@assets/pc/sound-off.png" />开启声音
        </span>
        <span v-if="is_audio" class="sound_box" @click="is_audio = !is_audio">
          <img src="@assets/pc/sound.png" />关闭声音
        </span>
        <span @click="isConnect = true">
          <img src="@assets/pc/serve.png" style="width: 24px;" />联系客服
        </span>
        <!-- <div class="user">
          <span>【{{ !username ? '牛牛账号' : username }}】</span>
          <span v-if="username" style="margin-left: 12px;">退出账号</span>
          <span v-if="!username" style="margin-left: 12px;">登录</span>
          <span v-if="!username" style="margin-left: 12px;">注册</span>
        </div> -->
      </div>
    </div>
    <!-- <APlayer style="display: none" ref="audio" :audio="audio_obj" loop="none" preload="auto" :mutex="false" :volume="1" mini/> -->
    <audio v-if="is_audio" style="display: none;" ref="audio" src="@assets/media/ring.wav" id="eventAudio"></audio>

    <div v-show="isConnect" class="connect-us-pop">
      <div>
        <div class="connect-us-title">联系客服 <i @click="isConnect = false" class="el-icon-close"></i></div>
        <div class="connect-us-cont">
          <div class="connect-us-row">
            <span>skype：</span>168kaicom@gmail.com
          </div>
          <div class="connect-us-row">
            <span>email：</span>168kaicn@gmail.com
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import VueMarqueeBar from "vue-marquee-bar"
// import { APlayer } from '@moefe/vue-aplayer'

export default {
  name: 'n-header',
  props: {
    fixed: {
      default: false
    },
    isAction: Boolean
  },
  components: {
    VueMarqueeBar,
    // APlayer
  },
  computed: {
    path() {
      return this.$route.path
    }
  },
  watch: {
    '$store.state.home.i_sound': {
      handler(bool) {
        if (bool) {
          this.play()
        } else {
          this.pause()
        }
      }
    }
  },
  data() {
    return {
      is_audio: false,
      username: '',
      notices: ['sfdsdfdfs'],
      audio_obj: {
        name: 'sb',
        artist: 'sb',
        cover: require('@assets/pc/voice.png'),
        url: require('@assets/media/ring.wav')
      },
      isConnect: false
    }
  },
  mounted() {
    this.get_notice()
  },
  methods: {
    pause() {
      if (this.is_audio) {
        this.$refs.audio.pause()
      }
    },
    play() {
      if (this.is_audio) {
        this.pause()
        this.$refs.audio.currentTime = 0;
        this.$refs.audio.play()
      }
    },
    get_notice() {
      this.notices = []
      this.$api.article(2, 1, 1).then(res => {
        const contents = res.data?.list || []
        if (contents.length > 0) {
          let str = contents[0]['content']
          str = str.replace(/\<p\>/, '')
          str = str.replace(/\<\/p\>/, '')
          str = '【' + contents[0]['title'] + '】' + str
          this.notices.push(str)
        }
      })
    },
    setHomeAction() {
      this.setHome(this, location.href)
    },
    addFavoriteAction() {
      this.addFavorite('牛&牛', location.href)
    },
    addFavorite(title, url) {
      try {
        window.external.addFavorite(url, title)
      } catch (e) {
        try {
          window.sidebar.addPanel(title, url, '')
        } catch (e) {
          alert('抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请使用Ctrl+D进行添加')
        }
      }
    },
    setHome(obj, url) {
      try {
        obj.style.behavior = 'url(#default#homepage)'
        obj.setHomePage(url)
      } catch (e) {
        if (window.netscape) {
          try {
            window.netscape.security.PrivilegeManager.enablePrivilege('UniversalXPConnect')
          } catch (e) {
            alert("抱歉，此操作被浏览器拒绝！\n\n请在浏览器地址栏输入“about:config”并回车然后将[signed.applets.codebase_principal_support]设置为'true'")
          }
        } else {
          alert('抱歉，您所使用的浏览器无法完成此操作。\n\n您需要手动将【' + url + '】设置为首页。')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/mixin";
@import "../scss/variables";

$btn-height: 22px;
$logo-size: (
  width: 168px,
  height: 72px,
);

.head_box {
  height: 100%;
  height: 42px;
  color: #fff;
  display: flex;
  align-items: center;

  .history_bt {
    flex-shrink: 0;
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration-line: none;

    &:hover {}

    >img {
      margin-right: 12px;
      width: 28px;
      height: auto;
    }
  }

  .notice_box {
    flex-shrink: 0;
    margin-left: 50px;
    margin-right: 10px;
    width: 720px;
  }

  .user_box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    >div {
      display: flex;
      align-items: center;
    }

    >span {
      cursor: pointer;
      display: flex;
      align-items: center;

      +span {
        margin-left: 30px;
      }

      >img {
        margin-right: 4px;
        width: 30px;
        height: auto;
      }
    }
  }
}

.header {
  width: 100%;
  background-color: white;
  left: 0;
  z-index: 999;

  .container {
    @include clearfix();
  }

  .nav_box {
    height: $header-height;
  }

  .logo {
    float: left;
    display: block;
    width: map-get($logo-size, width);
    height: map-get($logo-size, height);
    margin-top: ($header-height - map-get($logo-size, height)) / 2;
  }

  .btn {
    float: right;
    display: flex;
    align-items: center;
    height: $btn-height;
    margin-top: ($header-height - $btn-height) / 2;
    margin-left: 42px;
    cursor: pointer;

    img {
      width: 18px;
      height: 18px;
    }

    p {
      color: #969696;
      font-size: 16px;
      margin-left: 9px;
    }
  }
}

.font_red {
  color: #FA3E3E !important;
}

.connect-us-pop {
  >div:nth-child(1) {
    width: 420px;
    height: auto;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3001;

    .connect-us-title {
      padding: 0 10px;
      padding-left: 20px;
      line-height: 40px;
      font-size: 16px;
      color: #fff;
      background-color: rgb(250, 62, 62);
      display: flex;
      align-items: center;
      justify-content: space-between;

      >i {
        font-size: 20px;
        cursor: pointer;
      }
    }

    .connect-us-cont {
      padding: 15px 40px;
      padding-bottom: 25px;
      .connect-us-row {
        height: 40px;
        display: flex;
        align-items: center;
        >span {
          width: 60px;
        }
      }
    }
  }

  >div:nth-child(2) {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3000;
  }
}

@media screen and (min-width: 1420px) {
  .container {
    width: 1420px;
  }
}
</style>
