<template>
  <div class="home">
    <n-header is-action :fixed="true"></n-header>
    <div class="background">
      <!--<img src="@assets/pc/bg_home1.png" class="bg bg1" />
      <img src="@assets/pc/bg_home2.png" class="bg bg2" />
      <img src="@assets/pc/bg_home3.png" class="bg bg3" />
      <img src="@assets/pc/bg_home4.png" class="bg bg4" />-->
    </div>
    <div class="main container">
      <div class="types">
        <router-link tag="a" target="_blank" to="/lottery" class="item">
          <div class="content">
            <img src="@assets/pc/home_type_1.png" />
          </div>
        </router-link>
        <div class="item">
          <div class="content un">
            <img src="@assets/pc/home_type_2.png" />
          </div>
        </div>
        <div class="item">
          <div class="content un">
            <img src="@assets/pc/home_type_3.png" />
          </div>
        </div>
      </div>

      <div class="cont">
        <div v-for="(item, key) in data.url" :key="key">

          <div v-if="item.cate_name == '视频直播' || item.cate_name == '实用工具' || item.cate_name == '聊天软件' || item.cate_name == '财经资讯'" class="title">
            {{item.cate_name}}
            <span></span>
          </div>

          <el-row  v-if="item.cate_name == '视频直播' || item.cate_name == '实用工具' || item.cate_name == '聊天软件' || item.cate_name == '财经资讯'" :gutter="18">
            <el-col style="width: 20%;" v-for="(it, k) in item.picture" :key="k">
              <div class="item_box" @click="openURLAction(it.link_url)">
                <div>
                  <img :src="it.http_url"/>
                  <span>{{it.title}}</span>
                </div>
                <span>{{it.link_url}}</span>
              </div>
            </el-col>
          </el-row>

        </div> 

      </div>

    </div>
    <div style="margin-bottom: 20px;"></div>
    <n-footer></n-footer>
  </div>
</template>

<script>
import Header from '@components/pc/Header'
import Footer from '@components/pc/Footer'

export default {
  name: 'Home',
  components: {
    [Header.name]: Header,
    [Footer.name]: Footer
  },
  data () {
    return {
      data: {
        url: [],
        customer_service: []
      }
    }
  },
  mounted () {
    this.$loading.show()
    this.$api.index().then(res => {
      this.data = res.data
      this.$loading.hide()
    }).catch(err => {
      this.$loading.hide()
      this.$message.error(err)
    })
  },
  methods: {
    openURLAction (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@scss/pc/variables";
@import "~@scss/pc/mixin";

.main {
  padding-top: $header-height + 30px;

  > .types {
    @include clearfix();

    .item {
      float: left;
      width: 406px;
      margin-right: 31px;
      &:last-child {
        margin-right: 0;
      }
      .content {
        position: relative;
        color: #ff3d48;
        padding-left: 30px;
        cursor: pointer;
        &:first-child {
          padding-left: 0;
        }
        > img {
          width: 100%;
          height: auto;
        }

        &.un {
          cursor: auto;
        }

        .title {
          font-size: 36px;
        }
        .sub {
          font-size: 24px;
        }

        .icon {
          position: absolute;
          right: 57px;
          top: 28px;
        }
        .next {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 108px;
          height: 44px;
        }
      }
    }
  }

  .urls {
    background-color: white;
    border-radius: 8px;
    margin-top: 15px;
    border: 1px solid #f2f2f2;

    > .head {
      height: 50px;
      line-height: 50px;
      padding-left: 17px;
      border-bottom: 1px solid #f2f2f2;

      > img,
      > p {
        display: inline-block;
        vertical-align: middle;
      }

      > p {
        margin-left: 11px;
      }
    }

    > .content {
      padding-top: 11px;
      padding-bottom: 11px;
      margin-left: -16px;
      margin-right: -16px;
      @include clearfix();

      > .item {
        float: left;
        height: 20px;
        line-height: 20px;
        margin: 11px 62px;
        cursor: pointer;

        > img {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
        }

        > p {
          font-size: 15px;
          display: inline-block;
          margin-left: 10px;
          vertical-align: middle;
          width: 100px;
          @include ellipsis();
        }
      }
    }
  }
  .contact {
    margin-top: 15px;
    margin-left: -5px;
    margin-right: -5px;
    @include clearfix();

    .col {
      width: 33.33%;
      float: left;
      padding-left: 5px;
      padding-right: 5px;
    }

    .item {
      background-color: white;
      border-radius: 8px;
      border: 1px solid #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 110px;

      > .icon {
        width: 48px;
        height: 48px;
        object-fit: contain;
      }

      > .content {
        margin-left: 20px;

        > .title {
          font-size: 18px;
        }

        > .text {
          margin-top: 2px;
          font-size: 15px;
        }
      }
    }
  }
}
.background {
  position: fixed;
  width: 100%;
  top: $header-height;
  bottom: 0;
  background-color: #F1F2F3;
  z-index: -1;

  .bg {
    position: absolute;
    display: block;
  }

  .bg1 {
    left: 0;
    top: -$header-height;
    width: 445px;
    height: 1080px;
  }

  .bg2 {
    left: 13px;
    top: 579px;
    width: 202px;
    height: 122px;
  }

  .bg3 {
    right: 0;
    top: 258px;
    width: 360px;
    height: 565px;
  }
  .bg4 {
    right: 75px;
    top: 385px;
    width: 183px;
    height: 183px;
  }
}
.cont {
  margin: 0 auto;
  height: auto;
}
.title {
  padding-top: 37px;
  font-size: 20px;
  color: #222;
}
.title>span {
  margin-left: 14px;
  font-size: 14px;
  color: #A4A4A5;
}
.item_box {
  margin-top: 18px;
  width: 100%;
  height: 108px;
  border-radius: 6px;
  background-color: #fff;
  padding: 16px 18px;
  box-sizing: border-box;
  cursor: pointer;
}
.item_box>div {
  margin-bottom: 16px;
  font-size: 16px;
  color: #222;
  display: flex;
  align-items: center;
}
.item_box>div>img {
  margin-right: 13px;
  width: 22px;
  height: auto;
}
.item_box>span {
  display: block;
  font-size: 14px;
  color: #9A9A9A;
  word-break: break-all;
  word-wrap: break-word;
  text-align: justify;
}
.list_special_box {
  width: 100%;
  display: flex;
  .special_l {
    flex: 1;
    .cont_box {
      margin-top: 16px;
      padding: 0 20px;
      background-color: #fff;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      >div {
        padding: 18px 0;
        width: 20%;
        cursor: pointer;
        display: flex;
        align-items: center;
        >img {
          margin-right: 10px;
          width: 22px;
          height: auto;
        }
        >span {
          font-size: 16px;
          color: #333;
        }
      }
    }
  }
  .special_r {
    width: 240px;
    margin-left: 20px;
    .concat_box {
      margin-top: 16px;
      padding: 0 18px;
      background-color: #fff;
      border-radius: 6px;
      >div {
        padding-bottom: 22px;
        display: flex;
        >img {
          flex-shrink: 0;
          margin-top: 20px;
          margin-right: 10px;
          width: 34px;
          height: 34px;
        }
        >div {
          >div {
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 16px;
            color: #333;
          }
          >span {
            font-size: 14px;
            color: #A9A9A9;
          }
        }
      }
    }
  }
}
.border_b:first-child {
  border-bottom: 1px solid #E3E3E6;
}
</style>
