<template>
  <footer class="">
    <ul class="breadcrumb links">
      <router-link tag="li" to="/lottery/disclaimer" style="color: rgb(0, 112, 192);">免责声明</router-link>
      <li>Copyright © 2020 牛牛科技 版权所有</li>
    </ul>
    <!-- <p class="copyright">Copyright © 2020 牛牛科技 版权所有</p> -->
  </footer>
</template>

<script>
export default {
  name: 'n-footer'
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
footer {
  height: 90px;
  background-color: white;
  text-align: center;
  padding-top: 40px;
  box-sizing: border-box;
}
.breadcrumb {
  list-style: none;

  > li {
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: #FF3D48;
      transition:color 0.25s linear;
    }

    + li:before {
      content: "\007c";
      padding: 0 20px;
      color: #222;
    }
  }
}
.links {
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.copyright {
  font-size: 14px;
  color: #A5A5A5;
  margin-top: 26px;
}
</style>
